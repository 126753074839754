import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;


const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-maszyn-i-urzadzen'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing maszyn i urządzeń CNC, IT, medycznych, leśnych'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing maszyn i urządzeń CNC, IT, medycznych, leśnych</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

           </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <p>
                Już kilka lat prowadzisz firmę i czujesz w niej brak powiewu świeżości i nowoczesności?
                Może czas pomyśleć nad nowatorskimi rozwiązaniami? Co powiesz na nowe maszyny bądź
                urządzenia? a może coś używanego? Czemu nie? To dla ciebie przygotowaliśmy leasing
                dla firm, który niesie za sobą mnóstwo korzyści. Podatki? A co to takiego? Dzięki
                leasingowy część kosztów odliczysz od podatku, Twój portfel nie ucierpi, a Ty będziesz
                mieć satysfakcję, że oszczędności z konta nie „uciekły”. Przystępne formy leasingu na
                oświadczenie nikogo nie zmęczą,  ponieważ my załatwimy wszystkie formalności. Maksimum
                wygody i 100% zadowolenia.
              </p>
            </div>

            <div>
              <p>
                Maszyny i urządzenia sterowane numerycznie (CNC) są popularnym i często wybieranym przedmiotem
                leasingu. Wpływa na to ich solidność, długowieczność oraz fakt, iż mimo upływu czasu oraz
                regularnego użytkowania nie tracą na wartości.
              </p>
              <p>
                Jeśli zatem sprzęt tego rodzaj jest niezbędny w prowadzonym przez Państwa przedsiębiorstwie
                lub też do celów zarobkowych, inwestycyjnych, w przygotowanej przeze mnie ofercie z pewnością
                znajdą Państwo odpowiednie dla swojej firmy wyposażenie. Oferuję leasing wszystkich dostępnych
                na rynku urządzeń i maszyn ruchomych i nieruchomych, nowych, a także używanych.
              </p>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Finansujemy:</h2>

              <h3>Maszyny CNC</h3>
              <p>
                Frezarki CNC, tokarki CNC, Wykrawarki CNC, Plazmy, obrabiarki, krawędziarki, wypalarki,
                wycinarki, wytaczarki, laser, Tokarki (konwencjonalne, karuzelowe, ciężkie)
              </p>

              <h3>Urządzenia medyczne</h3>
              <p>
                Aparaty EKG, KTG, EEG, RTG,  do krioterapii, autoklaw, fotele dentystyczne, fotele ginekologiczne,
                laser medyczny, urządzenia laryngologiczne, okulistyczne, tomografy, dentystyczne, protetyczne,
                USG, farmaceutyczne,
              </p>

              <h3>Maszyny rolnicze i leśne</h3>
              <p>
                Ciągniki rolnicze, do zrywki drewna, agregaty, kombajny, kosiarki, ładowarki, brony,
                ładowacze czołowe, opryskiwacze, glebogryzarki, ładowacze czołowe, pługi, prasy, rozrzutniki,
                rozsiewacze, roztrząsacze, wozy asenizacyjne, wozy paszowe, paszowozy, zgrabiarki, mieszalniki pasz, suszarnie.
              </p>
            </div>

            <div>
              <br/>
              <br/>
              <h3>Pozostałe</h3>
              <p>
                Maszyny włókiennicze, poligraficzne, plotery, do metalu, traki taśmowe, do cięcia laserem, do produkcji
                wyrobów z drewna lub materiałów zastępczych, myjki wysokociśnieniowe i parowe, podnośniki, dźwigniki,
                wyciągarki, wciągarki, urządzenia wiertnicze, spychacze, frezarki, centra obróbcze, oklejarki, pilarki,
                piły, obcinarki, szlifierki, traki taśmowe, wiertarki, wkrętarki, zgrzewarki, mieszalniki, przecinarki,
                gilotyny, nitownice, piekarnicze, urządzenia do pomiarów geodezyjnych, kartograficznych, fotogrametrycznych,
                kotły grzewcze, kotły parowe, odlewnicze, suszarnia obrotowa, systemy wentylacji, chłodnicze, klimatyzacyjne,
                wentylatory, kamieniarskie, introligatorskie, etykieciarki, laser, prasy, gastronomiczne, spożywcze,
                włókiennicze, tkackie, prasowalnice, agregaty chłodnicze, papiernicze, cukiernicze, do przetłaczania,
                sprzężenia cieczy i gazów.
              </p>
            </div>
          </div>


          <div className={s_text.double}>
            <div>
              <h2>Leasing maszyn rolniczych i leśnych</h2>

              <p>
                W związku z wysokimi kosztami maszyn i urządzeń leśnych oraz rolniczych, ich popularną formą finansowania
                jest właśnie leasing. Szeroka oferta, minimum formalności oraz korzystne warunki finansowe pozwalają
                każdemu Zainteresowanemu szybko rozwinąć swoją firmę i wyposażyć ją we wszystkie urządzenia niezbędne
                do wykonywania codziennych obowiązków na polach i na terenach leśnych.
              </p>

              <p>
                Wychodząc naprzeciw oczekiwaniom moich Klientów, przygotowałem dla nich konkurencyjną ofertę
                finansowania sprzętów leśnych i rolniczych, która w każdym przypadku zostanie dopasowana do
                potrzeb i możliwości danego przedsiębiorstwa.
              </p>

              <h3>Przedmiot oferty</h3>

              <ul className={s_text.checkmark_list}>
                <li>Kombajny</li>
                <li>Ciągniki rolnicze</li>
                <li>Maszyny do prac leśnych</li>
                <li>Ładowacze rolnicze</li>
                <li>Agregaty uprawne</li>
                <li>Prasy do belowania</li>
                <li>Przyczepy rolnicze</li>
                <li>oraz wiele innych</li>
              </ul>
            </div>

            <div>
              <h2>Warunki leasingu maszyn i urządzeń</h2>
              <ul className={s_text.dash_list}>
                <li>Elastyczny okres leasingu do 84 miesięcy</li>
                <li>Wpłata wstępna od 5%</li>
                <li>Uproszczona procedura, finansowanie, minimum formalności (m.in. brak konieczności przedstawiania zaświadczeń z ZUS, KRUS, UG)</li>
                <li>Szybka decyzja oraz załatwienie wszystkich formalności</li>
                <li>Możliwość odliczenia 100% podatku VAT z tytułu umowy leasingu</li>
              </ul>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
